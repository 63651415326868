import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  InputAdornment,
} from "@mui/material";
import { Email, Lock } from "@mui/icons-material";
import { ReqLogin } from "../../services/auth";
import PopSnackbar from "../../components/snackbar";
import logo from "../../assets/brandity-logo.png";

export default function Login() {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");

  function HasUpperCase(str) {
    return /[A-Z]/.test(str);
  }

  function HandleError(message) {
    setButtonLoading(false);
    setError(true);
    setErrorDescription(message);
    setTimeout(() => {
      setError(false);
      setErrorDescription("");
    }, 3000);
  }

  async function SubmitLogin() {
    setButtonLoading(true);
    if (!email || !password) {
      HandleError("Please enter both email and password");
      return;
    }

    const email_formated = email.toLowerCase();
    const regex_email =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const email_verification = regex_email.test(email_formated);
    const password_verification = HasUpperCase(password) && password.length > 4;

    if (!email_verification) {
      HandleError("Email is not valid");
    } else if (!password_verification) {
      HandleError("Password is not valid");
    } else {
      const body = {
        email: email_formated,
        password,
      };
      const content = await ReqLogin(JSON.stringify(body));

      if (content.error) {
        HandleError(content.error);
      } else if (content.status === "OK") {
        localStorage.setItem("token", content.token);
        setButtonLoading(false);
        window.location.replace("/");
      } else {
        HandleError("Something went wrong, please try again later.");
      }
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{
        background: "rgb(244, 246, 248)",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "20px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.3)",
          maxWidth: "400px",
          width: "100%",
          animation: "bounceIn 0.6s ease",
          "@keyframes bounceIn": {
            "0%": {
              transform: "scale(0.8)",
              opacity: 0,
            },
            "50%": {
              transform: "scale(1.05)",
              opacity: 0.9,
            },
            "70%": {
              transform: "scale(0.95)",
            },
            "100%": {
              transform: "scale(1)",
              opacity: 1,
            },
          },
        }}
      >
        <Box display="flex" justifyContent="center" mb={3}>
          <img
            src={logo}
            alt="Logo"
            style={{
              width: "150px",
              height: "auto",
            }}
          />
        </Box>

        <Typography
          variant="h4"
          textAlign="center"
          marginBottom={3}
          fontWeight="bold"
          color="black"
        >
          Welcome Back
        </Typography>

        <TextField
          fullWidth
          variant="outlined"
          label="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          onKeyDown={(event) => event.key === "Enter" && SubmitLogin()}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <Email color="action" />
                </InputAdornment>
              ),
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "30px",
            },
          }}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          onKeyDown={(event) => event.key === "Enter" && SubmitLogin()}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <Lock color="action" />
                </InputAdornment>
              ),
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "30px",
            },
          }}
        />

        {error && <PopSnackbar message={errorDescription} />}

        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={SubmitLogin}
          disabled={buttonLoading}
          sx={{
            marginTop: 2,
            borderRadius: "30px",
            padding: "10px 0",
            backgroundColor: "black",
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.9)",
            },
            transition: "0.3s",
          }}
        >
          {buttonLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Login"
          )}
        </Button>
      </Box>
    </Box>
  );
}
