import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddPartner } from "../../services/partners";
import { TextField, Button } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import PopSnackbar from "../../components/snackbar/index.js";

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
    "& .MuiInputLabel-root": { color: "#555" },
    "&.Mui-disabled .MuiOutlinedInput-root": { backgroundColor: "#f0f0f0" },
  },
};

export default function CreatePartner() {
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [toastStatus, setToastStatus] = useState(false);
  const [toastDescription, setToastDescription] = useState("");

  const navigate = useNavigate();

  const handleError = (description) => {
    setError(true);
    setErrorDescription(description);
  };
  const handleFileRead = async (event) => {
    return await convertBase64(event);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleButtonClick = () => {
    document.querySelector("#file-input").click();
  };

  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  const submitForm = async () => {
    let photo = "";

    if (file) {
      photo = await handleFileRead(file);
    } else {
      handleStatus(
        "Please upload a photo",
        setToastStatus,
        setToastDescription
      );
      return;
    }

    if (name.length < 4) {
      handleStatus(
        "Name must be at least 4 characters",
        setToastStatus,
        setToastDescription
      );
      return;
    }

    const data = {
      name: name,
      logo: photo,
    };

    var jsonData = JSON.stringify(data);

    try {
      setLoading(true);
      const content = await AddPartner(jsonData);

      if (content.error) {
        handleStatus(
          content.error || "Something went wrong, please try again later.",
          setToastStatus,
          setToastDescription
        );
      } else if (content.status === "OK") {
        navigate("/partners");
      }
    } catch (err) {
      handleStatus(
        err.error || "Something went wrong, please try again later.",
        setToastStatus,
        setToastDescription
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {toastStatus && <PopSnackbar message={toastDescription} />}

      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        <div className="upload-container">
          <div className="input-circle" onClick={handleButtonClick}>
            <input
              type="file"
              id="file-input"
              name="photo"
              className="input-field"
              accept="image/*"
              onChange={(event) => {
                setFile(event.target.files[0]);
              }}
            />
            <div className="inner-circle">
              {file ? (
                <div className="inner-circle-img-container">
                  <img
                    src={URL.createObjectURL(file)}
                    alt="preview"
                    className="inner-circle-img"
                  />
                  <div className="add-photo-overlay">
                    <AddAPhotoIcon />
                    <span className="upload-text">Update photo</span>
                  </div>
                </div>
              ) : (
                <>
                  <img
                    src="https://via.placeholder.com/140"
                    alt="default"
                    className="inner-circle-img"
                  />
                  <div className="add-photo-overlay">
                    <AddAPhotoIcon />
                    <span className="upload-text">Upload photo</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <span className="upload-text-2">
            Allowed *.jpeg, *.jpg, *.png
            <br />
            max size of 3.1 MB
          </span>

          <TextField
            sx={styles.select}
            label="Name"
            variant="outlined"
            style={{ marginBlock: 20, width: "50%" }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <button
            style={{ width: "50%" }}
            onClick={submitForm}
            className="add-video-button"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
