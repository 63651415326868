import React, { useState, useEffect } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Modal,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Divider,
} from "@mui/material";
import { Plus, Minus, Folder, MapPin } from "react-feather";
import { getFinalCategoriesWithFirstAndLast } from "../../../components/getfinalcategoriesfirstandlast";

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
    "& .MuiInputLabel-root": { color: "#555" },
    "&.Mui-disabled .MuiOutlinedInput-root": { backgroundColor: "#f0f0f0" },
  },
};

export default function CategoriesVideoTab({
  categories,
  setCategories,
  availableCategories,
  locations,
  setLocations,
  availableLocations,
  partners,
  setPartners,
  availablePartners,
  setAllPartners,
  setAllLocations,
  preview,
}) {
  const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
  const [isLocationModalOpen, setLocationModalOpen] = useState(false);
  const [isPartnerModalOpen, setPartnerModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);

  const toggleCategoryModal = () => setCategoryModalOpen(!isCategoryModalOpen);
  const toggleLocationModal = () => setLocationModalOpen(!isLocationModalOpen);
  const togglePartnerModal = () => setPartnerModalOpen(!isPartnerModalOpen);

  const handleSearchChange = (e) => setSearchTerm(e.target.value.toLowerCase());

  const finalCategories =
    getFinalCategoriesWithFirstAndLast(availableCategories);

  const filteredCategories = finalCategories.filter((cat) =>
    cat.displayName.toLowerCase().includes(searchTerm)
  );

  useEffect(() => {
    setSelectedCategories(categories);
  }, [categories]);

  useEffect(() => {
    setSelectedLocations(locations);
  }, [locations]);

  useEffect(() => {
    setSelectedPartners(partners);
  }, [partners]);

  const filteredLocations = availableLocations.filter(
    (loc) =>
      loc.name.toLowerCase().includes(searchTerm) &&
      selectedPartners.some((partner) => partner.ID === loc.partner_id)
  );

  const handleSelection = (item, selectedItems, setSelectedItems) => {
    if (selectedItems.some((i) => i.ID === item.ID)) {
      setSelectedItems(selectedItems.filter((i) => i.ID !== item.ID));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const toggleSelectAll = (availableItems, selectedItems, setSelectedItems) => {
    if (selectedItems.length === availableItems.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems([...availableItems]);
    }
  };

  const saveCategories = () => {
    setCategories(selectedCategories);
    toggleCategoryModal();
  };

  const saveLocations = () => {
    setLocations(selectedLocations);
    toggleLocationModal();

    checkIfAllLocations(selectedLocations, selectedPartners);
  };

  const savePartners = () => {
    setPartners(selectedPartners);
    togglePartnerModal();

    //remove the locations that are not associated with the selected partners
    const updatedLocations = availableLocations.filter((loc) =>
      selectedPartners.some((partner) => partner.ID === loc.partner_id)
    );

    if (selectedPartners.length === availablePartners.length) {
      setLocations(updatedLocations);
      setSelectedLocations(updatedLocations);
    }

    checkIfAllPartners(selectedPartners);
    checkIfAllLocations(updatedLocations, selectedPartners);
  };

  const checkIfAllPartners = (sentPartners) => {
    if (sentPartners.length === availablePartners.length) {
      setAllPartners(true);
      setLocations(availableLocations);
    } else {
      setAllPartners(false);
    }
  };

  const checkIfAllLocations = (sentLocations, partners) => {
    const updatedLocations = availableLocations.filter((loc) =>
      partners.some((partner) => partner.ID === loc.partner_id)
    );

    if (sentLocations.length === updatedLocations.length) {
      setAllLocations(true);
    } else {
      setAllLocations(false);
    }
  };

  const handleRemoveCategory = (ID) => {
    const updatedCategories = selectedCategories.filter((c) => c.ID !== ID);
    setCategories(updatedCategories);
    setSelectedCategories(updatedCategories);
  };

  const handleRemoveLocation = (ID) => {
    const updatedLocations = selectedLocations.filter((l) => l.ID !== ID);
    setLocations(updatedLocations);
    setSelectedLocations(updatedLocations);

    checkIfAllLocations(updatedLocations, selectedPartners);
  };

  const handleRemovePartner = (ID) => {
    const updatedPartners = selectedPartners.filter((p) => p.ID !== ID);
    setPartners(updatedPartners);
    setSelectedPartners(updatedPartners);

    const updatedLocations = availableLocations.filter(
      (loc) => loc.partner_id !== ID
    );
    setLocations(updatedLocations);
    setSelectedLocations(updatedLocations);

    checkIfAllPartners(updatedPartners);
    checkIfAllLocations(updatedLocations, updatedPartners);
  };

  return (
    <>
      <h1 className="add-video-title">Select Categories and Locations</h1>
      <Divider style={{ marginBottom: "20px" }} />

      {/* Categories Section */}
      <div style={{ display: "flex", columnGap: "7px", alignItems: "center" }}>
        <Folder fontSize={"1.25rem"} />
        <span style={{ fontSize: "1.25rem" }}>Video categories</span>
        <IconButton onClick={toggleCategoryModal} disabled={preview}>
          <Plus />
        </IconButton>
      </div>
      <List>
        {categories.map((category) => (
          <ListItem
            key={category.ID}
            style={{ display: "flex", alignItems: "center" }}
          >
            <ListItemText primary={category.displayName} />{" "}
            <IconButton
              onClick={() => handleRemoveCategory(category.ID)}
              disabled={preview}
            >
              <Minus />
            </IconButton>
          </ListItem>
        ))}
      </List>

      {/* Partners Section */}
      {categories.some((c) => c.ID === 2) && (
        <>
          <div
            style={{ display: "flex", columnGap: "7px", alignItems: "center" }}
          >
            <Folder fontSize={"1.25rem"} />
            <span style={{ fontSize: "1.25rem" }}>Video partners</span>
            <IconButton onClick={togglePartnerModal} disabled={preview}>
              <Plus />
            </IconButton>
          </div>
          <List>
            {partners.map((partner) => (
              <ListItem
                key={partner.ID}
                style={{ display: "flex", alignItems: "center" }}
              >
                <ListItemText primary={partner.name} />
                <IconButton
                  onClick={() => handleRemovePartner(partner.ID)}
                  disabled={preview}
                >
                  <Minus />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </>
      )}

      {/* Locations Section */}
      {partners.length > 0 &&
        partners.length !== availablePartners.length &&
        categories.some((c) => c.ID === 2) && (
          <>
            <div
              style={{
                display: "flex",
                columnGap: "7px",
                alignItems: "center",
              }}
            >
              <MapPin fontSize={"1.25rem"} />
              <span style={{ fontSize: "1.25rem" }}>Video locations</span>
              <IconButton onClick={toggleLocationModal} disabled={preview}>
                <Plus />
              </IconButton>
            </div>
            <List>
              {locations.map((location) => (
                <ListItem
                  key={location.ID}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <ListItemText primary={location.name} />
                  <IconButton
                    onClick={() => handleRemoveLocation(location.ID)}
                    disabled={preview}
                  >
                    <Minus />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </>
        )}

      {/* Category Modal */}
      <Modal open={isCategoryModalOpen} onClose={toggleCategoryModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            maxHeight: "80vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <h2 style={{ fontFamily: "SeagoeUIBold" }}>Select Categories</h2>
          <TextField
            sx={styles.select}
            fullWidth
            placeholder="Search categories"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ marginBottom: "16px" }}
          />
          <button
            className="add-video-button"
            onClick={() =>
              toggleSelectAll(
                finalCategories,
                selectedCategories,
                setSelectedCategories
              )
            }
            style={{ marginBottom: "16px", width: "100%" }}
          >
            {selectedCategories?.length === finalCategories.length
              ? "Deselect All"
              : "Select All"}
          </button>
          <Box
            sx={{
              maxHeight: "50vh",
              overflowY: "scroll",
              marginBottom: "16px",
              border: "1px solid #ddd",
            }}
          >
            <List>
              {filteredCategories.map((category) => (
                <ListItem key={category.ID}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedCategories?.some(
                          (c) => c.ID === category.ID
                        )}
                        onChange={() =>
                          handleSelection(
                            category,
                            selectedCategories,
                            setSelectedCategories
                          )
                        }
                      />
                    }
                    label={category.displayName}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
            <button className="add-video-button" onClick={toggleCategoryModal}>
              Cancel
            </button>
            <button className="add-video-button" onClick={saveCategories}>
              Save
            </button>
          </Box>
        </Box>
      </Modal>

      {/* Partners Modal */}
      <Modal open={isPartnerModalOpen} onClose={togglePartnerModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            maxHeight: "80vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <h2 style={{ fontFamily: "SeagoeUIBold" }}>Select Partners</h2>
          <TextField
            sx={styles.select}
            fullWidth
            placeholder="Search partners"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ marginBottom: "16px" }}
          />
          <button
            className="add-video-button"
            onClick={() =>
              toggleSelectAll(
                availablePartners,
                selectedPartners,
                setSelectedPartners
              )
            }
            style={{ marginBottom: "16px", width: "100%" }}
          >
            {selectedPartners.length === availablePartners.length
              ? "Deselect All"
              : "Select All"}
          </button>
          <Box
            sx={{
              maxHeight: "50vh",
              overflowY: "scroll",
              marginBottom: "16px",
              border: "1px solid #ddd",
            }}
          >
            <List>
              {availablePartners.map((partner) => (
                <ListItem key={partner.ID}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedPartners.some(
                          (c) => c.ID === partner.ID
                        )}
                        onChange={() =>
                          handleSelection(
                            partner,
                            selectedPartners,
                            setSelectedPartners
                          )
                        }
                      />
                    }
                    label={partner.name}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
            <button className="add-video-button" onClick={togglePartnerModal}>
              Cancel
            </button>
            <button className="add-video-button" onClick={savePartners}>
              Save
            </button>
          </Box>
        </Box>
      </Modal>

      {/* Location Modal */}
      <Modal open={isLocationModalOpen} onClose={toggleLocationModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            maxHeight: "80vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <h2 style={{ fontFamily: "SeagoeUIBold" }}>Select Locations</h2>
          <TextField
            sx={styles.select}
            fullWidth
            placeholder="Search locations"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ marginBottom: "16px" }}
          />
          <button
            className="add-video-button"
            onClick={() =>
              toggleSelectAll(
                filteredLocations,
                selectedLocations,
                setSelectedLocations
              )
            }
            style={{ marginBottom: "16px", width: "100%" }}
          >
            {selectedLocations.length === filteredLocations.length
              ? "Deselect All"
              : "Select All"}
          </button>
          <Box
            sx={{
              maxHeight: "50vh",
              overflowY: "scroll",
              marginBottom: "16px",
              border: "1px solid #ddd",
            }}
          >
            <List>
              {filteredLocations.map((location) => (
                <ListItem key={location.ID}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedLocations.some(
                          (l) => l.ID === location.ID
                        )}
                        onChange={() =>
                          handleSelection(
                            location,
                            selectedLocations,
                            setSelectedLocations
                          )
                        }
                      />
                    }
                    label={location.name}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
            <button className="add-video-button" onClick={toggleLocationModal}>
              Cancel
            </button>
            <button className="add-video-button" onClick={saveLocations}>
              Save
            </button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
