import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import { alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

function AccountPopover({
  info,
  open,
  handleClose,
  handleOpen,
  iconButtonRef,
}) {
  const navigate = useNavigate();

  const logOut = async () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <>
      <IconButton
        onClick={(e) => handleOpen(e)}
        sx={{
          marginRight: 2.5,
          width: 40,
          height: 40,
          background: (theme) => alpha("#90ee90", 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, #90ee90 0%, #800080 100%)`,
          }),
        }}
        ref={iconButtonRef}
      >
        <Avatar
          className="avatar-popover"
          alt={info.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px white`,
          }}
        >
          {info.name.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography
            variant="subtitle2"
            noWrap
            style={{ fontFamily: "SeagoeUIBold" }}
          >
            {info.name}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Divider sx={{ borderStyle: "dashed", m: 0 }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={logOut}
          sx={{ typography: "body2", color: "error.main", py: 1.5 }}
          style={{ fontFamily: "SeagoeUI" }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}

export default AccountPopover;
