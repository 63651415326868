import * as React from "react";
import { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { TextFormField } from "../../components/textform/TextFormField.tsx";
import * as yup from "yup";
import { UpdateUser, UpdateUserPass, FindUser } from "../../services/user.js";
import { useNavigate } from "react-router-dom";
import YupPassword from "yup-password";
import MultiSelectFormField from "../../components/multiselectform/MultiSelectFormField.tsx";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

YupPassword(yup);

export const form = [
  {
    name: "name",
    label: "Full Name",
    disabled: false,
    component: TextFormField,
    style: { width: "49%" },
  },
  {
    name: "email",
    label: "Email",
    disabled: false,
    component: TextFormField,
    style: { width: "49%" },
  },
  {
    name: "role",
    label: "Role",
    disabled: false,
    multiple: true,
    component: MultiSelectFormField,
    select: true,
    options: [
      { value: "ROLE_ADMIN", label: "Admin" },
      { value: "ROLE_USER", label: "User" },
    ],
    style: { width: "49%" },
  },
];

export const formPass = [
  {
    name: "password",
    label: "Password",
    disabled: false,
    component: TextFormField,
    type: "password",
    style: { width: "49%" },
  },
  {
    name: "confirm_password",
    label: "Confirm Password",
    disabled: false,
    component: TextFormField,
    type: "password",
    style: { width: "49%" },
  },
];

export const validation = yup.object().shape({
  name: yup.string().required("Full Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  role: yup.array().min(1, "At least one role is required"),
});

export const validationPass = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(
      8,
      "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "Password must contain at least 1 lowercase letter")
    .minUppercase(1, "Password must contain at least 1 uppercase letter")
    .minNumbers(1, "Password must contain at least 1 number")
    .minSymbols(1, "Password must contain at least 1 special character"),
  confirm_password: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default function EditUser() {
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [user, setUser] = useState(null);
  const [togglePassForm, setTogglePassForm] = useState(false);
  const navigate = useNavigate();

  const handleError = (description) => {
    setError(true);
    setErrorDescription(description);
  };

  const submitForm = async (values) => {
    const data = {
      name: values.name,
      email: values.email,
      role: values.role,
      password: values.password,
      confirm_password: values.password,
    };

    const jsonData = JSON.stringify(data);

    try {
      setLoading(true);
      const content = await UpdateUser(jsonData, id);

      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        navigate("/users");
      }
    } catch {
      handleError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const submitFormPass = async (values) => {
    const data = { password: values.password };
    const jsonData = JSON.stringify(data);

    try {
      setLoading(true);
      const content = await UpdateUserPass(jsonData, id);

      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        alert("Password updated");
        setTogglePassForm(false);
      }
    } catch {
      handleError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const url = window.location.href;
      const newId = url.split("/").pop();
      setId(newId);
      const content = await FindUser(newId);
      setUser(content.data);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        {error && (
          <div className="error-container">
            <span className="error-text">{errorDescription}</span>
          </div>
        )}
      </div>
      {user != null && (
        <div className="form-container">
          <Formik
            validationSchema={validation}
            initialValues={{
              name: user.name,
              email: user.email,
              role: user?.role,
            }}
            onSubmit={(values) => submitForm(values)}
          >
            {() => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="form-position">
                  {form.map((snapshot, key) => (
                    <Field {...snapshot} key={key} />
                  ))}
                </div>
                <button className="submit-form" type="submit">
                  {loading ? <CircularProgress size={24} /> : "Save Changes"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}

      <div className="update-password-container">
        <button
          className="toggle-pass-btn"
          onClick={() => setTogglePassForm(!togglePassForm)}
        >
          Update Password
          {!togglePassForm ? (
            <KeyboardArrowDownIcon />
          ) : (
            <KeyboardArrowUpIcon />
          )}
        </button>
        {user != null && (
          <div
            style={{
              height: "auto",
              maxHeight: togglePassForm ? "300px" : 0,
              overflow: "hidden",
              transition: "max-height 0.5s",
            }}
          >
            <Formik
              validationSchema={validationPass}
              initialValues={{
                password: "",
                confirm_password: "",
              }}
              onSubmit={(values) => {
                submitFormPass(values);
              }}
            >
              {() => (
                <Form
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="form-position">
                    {formPass.map((snapshot, key) => {
                      return <Field {...snapshot} key={key} />;
                    })}
                  </div>
                  <button
                    className="submit-form"
                    type="submit"
                    style={{ marginBottom: "0px" }}
                  >
                    {loading ? <CircularProgress size={24} /> : "Save Changes"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
      {/* </div> */}
    </div>
  );
}
