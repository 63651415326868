import "./assets/App.css";
import React from "react";
import {
  Route,
  Routes,
  BrowserRouter,
  useNavigate,
  Navigate,
} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import loadable from "@loadable/component";
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import FindMy from "./services/auth";
import Login from "./pages/login";
import AddLocation from "./pages/locations/add";
import EditLocation from "./pages/locations/edit";
import AddVideo from "./pages/videos/add/index";
import EditVideo from "./pages/videos/edit/index";
import CreateUser from "./pages/users/create.js";
import EditUser from "./pages/users/edit";
import CreatePartner from "./pages/partners/add.js";

const routesList = {
  dashboard: {
    title: "Dashboard",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: true,
      },
    ],
  },
  create_user: {
    title: "Create new user",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "User",
        Link: "/users",
        isActive: false,
      },
      {
        Name: "New user",
        Link: "/users/create",
        Title: "Create new user",
        isActive: true,
      },
    ],
  },
  user: {
    title: "List of users",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "User",
        Link: "/users",
        Title: "List of users",
        isActive: true,
      },
    ],
  },
  update_user: {
    title: "Update user",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "User",
        Link: "/users",
        isActive: false,
      },
      {
        Name: "Update user",
        Link: "/users/:id",
        Title: "Update user",
        isActive: true,
      },
    ],
  },
  loops: {
    title: "List of loops",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "List of loops",
        Link: "/loops",
        isActive: true,
      },
    ],
  },
  devices: {
    title: "List of devices",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "List of devices",
        Link: "/devices",
        isActive: true,
      },
    ],
  },
  videos: {
    title: "List of videos",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "List of videos",
        Link: "/videos",
        isActive: true,
      },
    ],
  },
  create_video: {
    title: "Create new video",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "List of videos",
        Link: "/videos",
        isActive: false,
      },
      {
        Name: "Create new video",
        Link: "/videos/create",
        Title: "Create new video",
        isActive: true,
      },
    ],
  },
  edit_video: {
    title: "View video",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "List of videos",
        Link: "/videos",
        isActive: false,
      },
      {
        Name: "View video",
        Link: "/videos/:id",
        Title: "Create new video",
        isActive: true,
      },
    ],
  },
  category: {
    title: "List of categories",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "List of categories",
        Link: "/category",
        isActive: true,
      },
    ],
  },
  locations: {
    title: "List of locations",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "List of locations",
        Link: "/locations",
        isActive: true,
      },
    ],
  },
  create_location: {
    title: "Create new location",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "List of locations",
        Link: "/locations",
        isActive: false,
      },
      {
        Name: "New location",
        Link: "/locations/create",
        Title: "Create new location",
        isActive: true,
      },
    ],
  },
  edit_location: {
    title: "View location",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "List of locations",
        Link: "/locations",
        isActive: false,
      },
      {
        Name: "View location",
        Link: "/locations/:id",
        Title: "Create new location",
        isActive: true,
      },
    ],
  },
  logs: {
    title: "Logs",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "Logs",
        Link: "/logs",
        isActive: true,
      },
    ],
  },
  partners: {
    title: "List of partners",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "List of partners",
        Link: "/partners",
        isActive: true,
      },
    ],
  },
  create_partner: {
    title: "Create new partner",
    list: [
      {
        Name: "Dashboard",
        Link: "/",
        isActive: false,
      },
      {
        Name: "List of partners",
        Link: "/partners",
        isActive: false,
      },
      {
        Name: "New partner",
        Link: "/partners/create",
        Title: "Create new partner",
        isActive: true,
      },
    ],
  },
};

const Dashboard = loadable(() => import("./pages/dashboard"));
const User = loadable(() => import("./pages/users"));
const Category = loadable(() => import("./pages/category"));
const Devices = loadable(() => import("./pages/devices"));
const Videos = loadable(() => import("./pages/videos"));
const Locations = loadable(() => import("./pages/locations"));
const Logs = loadable(() => import("./pages/settings/logs"));
const Partners = loadable(() => import("./pages/partners"));
const Loops = loadable(() => import("./pages/loops"));

function RequireAuth({ children, isLogged, info, pathName }) {
  const navigator = useNavigate();

  return isLogged ? (
    <>
      <Header info={info} />
      <div>
        <Sidebar info={info} />
        <div className="page-wrapper">
          <div className="route">
            <div className="header-default">{routesList[pathName].title}</div>
            {routesList[pathName].list.map((route, key) => {
              return (
                <span
                  key={key}
                  onClick={() =>
                    route.isActive === false ? navigator(route.Link) : null
                  }
                >
                  <span
                    className={
                      route.isActive ? "header-active" : "header-inactive"
                    }
                  >
                    {route.Name}
                  </span>
                  {key !== routesList[pathName].list.length - 1 && (
                    <span
                      style={{
                        marginInline: 15,
                        color: "rgba(35,43,53, 0.5)",
                      }}
                    >
                      •
                    </span>
                  )}
                </span>
              );
            })}
          </div>
          <div className="content container-fluid">{children}</div>
        </div>
      </div>
    </>
  ) : (
    <Navigate to={"/login"} />
  );
}

const App = () => {
  const [isLogged, setIsLogged] = React.useState(null);
  const [info, setInfo] = React.useState(null);

  async function isUserAuthenticated() {
    const content = await FindMy();
    if (content.status === "ok") {
      setIsLogged(true);
      setInfo(content.data);
    } else {
      localStorage.removeItem("token");
      setIsLogged(false);
      setInfo(null);
    }
  }

  React.useEffect(() => {
    isUserAuthenticated();
  }, []);

  const checkPermission = (param) => {
    if (info && info.role.includes("ROLE_ADMIN")) {
      return true;
    }
    return false;
  };

  if (isLogged === null) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="main-wrapper">
      <BrowserRouter>
        <Routes>
          {/* dashboard start */}
          <Route
            path="/"
            element={
              <RequireAuth
                pathName={"dashboard"}
                isLogged={isLogged}
                info={info}
              >
                <Dashboard info={info}></Dashboard>
              </RequireAuth>
            }
          />
          {/* dashboard end */}
          {/* users start */}
          {checkPermission() && (
            <>
              <Route
                path="/users"
                element={
                  <RequireAuth
                    isLogged={isLogged}
                    info={info}
                    pathName={"user"}
                  >
                    <User />
                  </RequireAuth>
                }
              />

              <Route
                path="/users/create"
                element={
                  <RequireAuth
                    isLogged={isLogged}
                    info={info}
                    pathName={"create_user"}
                  >
                    <CreateUser />
                  </RequireAuth>
                }
              />

              <Route
                path="/users/:id"
                element={
                  <RequireAuth
                    isLogged={isLogged}
                    info={info}
                    pathName={"update_user"}
                  >
                    <EditUser />
                  </RequireAuth>
                }
              />
            </>
          )}

          <Route
            path="/loops"
            element={
              <RequireAuth isLogged={isLogged} info={info} pathName={"loops"}>
                <Loops />
              </RequireAuth>
            }
          />

          {/* users end */}
          {/* category start */}
          <Route
            path="/category"
            element={
              <RequireAuth
                pathName={"category"}
                isLogged={isLogged}
                info={info}
              >
                <Category></Category>
              </RequireAuth>
            }
          />
          {/* category end */}
          {/* partners start */}
          <Route
            path="/partners"
            element={
              <RequireAuth
                pathName={"partners"}
                isLogged={isLogged}
                info={info}
              >
                <Partners></Partners>
              </RequireAuth>
            }
          />
          <Route
            path="/partners/create"
            element={
              <RequireAuth
                pathName={"create_partner"}
                isLogged={isLogged}
                info={info}
              >
                <CreatePartner></CreatePartner>
              </RequireAuth>
            }
          ></Route>
          {/* partners end */}
          {/* devices start */}
          <Route
            path="/devices"
            element={
              <RequireAuth pathName={"devices"} isLogged={isLogged} info={info}>
                <Devices></Devices>
              </RequireAuth>
            }
          />
          {/* devices end */}
          {/* videos start */}
          <Route
            path="/videos"
            element={
              <RequireAuth pathName={"videos"} isLogged={isLogged} info={info}>
                <Videos />
              </RequireAuth>
            }
          />
          <Route
            path="/videos/create"
            element={
              <RequireAuth
                pathName={"create_video"}
                isLogged={isLogged}
                info={info}
              >
                <AddVideo />
              </RequireAuth>
            }
          />
          <Route
            path="/videos/:id"
            element={
              <RequireAuth
                pathName={"edit_video"}
                isLogged={isLogged}
                info={info}
              >
                <EditVideo />
              </RequireAuth>
            }
          />
          {/* videos end */}
          {/* locations start */}
          <Route
            path="/locations"
            element={
              <RequireAuth
                pathName={"locations"}
                isLogged={isLogged}
                info={info}
              >
                <Locations></Locations>
              </RequireAuth>
            }
          />
          <Route
            path="/locations/create"
            element={
              <RequireAuth
                pathName={"create_location"}
                isLogged={isLogged}
                info={info}
              >
                <AddLocation></AddLocation>
              </RequireAuth>
            }
          />
          <Route
            path="/locations/:id"
            element={
              <RequireAuth
                pathName={"edit_location"}
                isLogged={isLogged}
                info={info}
              >
                <EditLocation></EditLocation>
              </RequireAuth>
            }
          />
          {/* locations end */}
          {/* settings start */}
          {checkPermission() && (
            <>
              <Route
                path="/logs"
                element={
                  <RequireAuth
                    pathName={"logs"}
                    isLogged={isLogged}
                    info={info}
                  >
                    <Logs info={info}></Logs>
                  </RequireAuth>
                }
              />
            </>
          )}

          {/* settings end */}
          <Route path="/login" element={<Login />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
