export const VideoPreview = ({ url, onClose }) => {
  const overlay = document.createElement("div");
  overlay.style.position = "fixed";
  overlay.style.top = "0";
  overlay.style.left = "0";
  overlay.style.width = "100vw";
  overlay.style.height = "100vh";
  overlay.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
  overlay.style.zIndex = "1002";

  const videoContainer = document.createElement("div");
  videoContainer.style.position = "fixed";
  videoContainer.style.top = "50%";
  videoContainer.style.left = "50%";
  videoContainer.style.transform = "translate(-50%, -50%)";
  videoContainer.style.backgroundColor = "#000";
  videoContainer.style.zIndex = "1003";

  const closeButton = document.createElement("button");
  closeButton.innerText = "Close";
  closeButton.style.position = "absolute";
  closeButton.style.top = "10px";
  closeButton.style.right = "10px";
  closeButton.style.zIndex = "1001";
  closeButton.style.backgroundColor = "#fff";
  closeButton.style.border = "none";
  closeButton.style.padding = "10px";
  closeButton.style.cursor = "pointer";

  const videoElement = document.createElement("video");
  videoElement.style.maxWidth = "90vw";
  videoElement.style.maxHeight = "90vh";
  videoElement.style.display = "block";
  videoElement.controls = true;
  videoElement.autoplay = true;

  const sourceElement = document.createElement("source");
  sourceElement.src = url;
  sourceElement.type = "video/mp4";
  videoElement.appendChild(sourceElement);

  const spinner = document.createElement("div");
  spinner.style.position = "absolute";
  spinner.style.top = "50%";
  spinner.style.left = "50%";
  spinner.style.transform = "translate(-50%, -50%)";
  spinner.style.width = "50px";
  spinner.style.height = "50px";
  spinner.style.border = "5px solid rgba(255, 255, 255, 0.3)";
  spinner.style.borderTop = "5px solid #fff";
  spinner.style.borderRadius = "50%";
  spinner.style.animation = "spin 1s linear infinite";
  spinner.style.display = "none";

  // Add spinner animation keyframes
  const style = document.createElement("style");
  style.textContent = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
  document.head.appendChild(style);

  // Event Handlers
  const onError = (event) => {
    console.error("Playback error:", event);
    alert("An error occurred during video playback.");
  };

  const onStalled = () => {
    console.log("Playback stalled. Attempting recovery...");
    if (!videoElement.paused) {
      videoElement.play().catch((err) => {
        console.error("Error recovering from stall:", err);
      });
    }
  };

  const onWaiting = () => {
    console.log("Video is buffering...");
    if (!videoElement.paused) {
      videoElement.play().catch((err) => {
        console.error("Error recovering from buffering:", err);
      });
    }
  };

  const onSeeked = () => {
    console.log("Seek completed.");
    videoElement.play().catch((err) => {
      console.error("Error playing after seek:", err);
    });
  };

  const onCanPlay = () => {
    console.log("Video is ready to play.");
    videoElement
      .play()
      .catch((err) => console.error("Error starting playback:", err));
  };

  const onPlaying = () => {
    console.log("Video is playing.");
  };

  // Attach Event Listeners
  videoElement.addEventListener("error", onError);
  videoElement.addEventListener("stalled", onStalled);
  videoElement.addEventListener("waiting", onWaiting);
  videoElement.addEventListener("seeked", onSeeked);
  videoElement.addEventListener("canplay", onCanPlay);
  videoElement.addEventListener("playing", onPlaying);

  const closeVideo = () => {
    videoElement.pause();
    videoElement.src = ""; // Unload the video
    videoElement.removeEventListener("error", onError);
    videoElement.removeEventListener("stalled", onStalled);
    videoElement.removeEventListener("waiting", onWaiting);
    videoElement.removeEventListener("seeked", onSeeked);
    videoElement.removeEventListener("canplay", onCanPlay);
    videoElement.removeEventListener("playing", onPlaying);
    document.body.removeChild(overlay);
    document.body.removeChild(videoContainer);
    document.head.removeChild(style);
    onClose();
  };

  // Close button logic
  closeButton.addEventListener("click", closeVideo);

  // Close on clicking outside the video container
  overlay.addEventListener("click", (event) => {
    if (!videoContainer.contains(event.target)) {
      closeVideo();
    }
  });

  // Append Elements
  videoContainer.appendChild(closeButton);
  videoContainer.appendChild(videoElement);
  videoContainer.appendChild(spinner);
  document.body.appendChild(overlay);
  document.body.appendChild(videoContainer);

  // Debug Info
  videoElement.addEventListener("timeupdate", () => {
    console.log(`Current Time: ${videoElement.currentTime}`);
  });

  videoElement.addEventListener("ended", () => {
    console.log("Video playback ended.");
  });
};
