import fetcher from "../helpers/fetcher";

export async function GetTags() {
  let path = "/tags";

  let request = await fetcher(path, "GET");

  let content = await request.json();

  return content;
}
