import fetcher from "../helpers/fetcher";

export async function GetAllVideos() {
  let path = `/videos/all`;
  let request = await fetcher(path, "GET");
  let content = request.json();
  return content;
}

export async function GetVideosByCategory(
  category_id = 0,
  page = 0,
  searchTerm = ""
) {
  let path = `/videos?category_id=${category_id}&page=${page}&search=${searchTerm}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function UploadVideo(
  formData,
  allLocations = false,
  allPartners = false
) {
  let path = `/videos?all_locations=${allLocations}&all_partners=${allPartners}`;

  let request = await fetcher(path, "POST", formData, false, true);

  let content = await request.json();

  return content;
}

export async function DownloadVideo(video_id) {
  let path = `/videos/${video_id}/download`;

  let request = await fetcher(path, "GET", null, false, true);

  let content = await request.blob();

  let contentDisposition = request.headers.get("content-disposition");

  let filename = contentDisposition.split("filename=")[1];
  if (!filename) {
    throw new Error("Filename is missing in Content-Disposition header");
  }

  filename = filename.replace(/"/g, "");

  return { content, filename };
}

export async function UpdateVideoName(video_id, body) {
  let path = `/videos/${video_id}`;

  let request = await fetcher(path, "PUT", body);

  let content = await request.json();

  return content;
}

export async function DeleteVideo(video_id, category_id) {
  let path = `/videos/${video_id}?category_id=${category_id}`;

  let request = await fetcher(path, "DELETE");

  let content = await request.json();

  return content;
}

export async function DeleteVideoGlobal(id) {
  let path = `/videos/${id}/global`;

  let request = await fetcher(path, "DELETE");

  let content = await request.json();

  return content;
}

export async function AddVideoToCategory(video_id, category_id) {
  let path = `/videos/categories?video_id=${video_id}&category_id=${category_id}`;

  let request = await fetcher(path, "POST");

  let content = await request.json();

  return content;
}

export async function AddVideoToLocation(video_id, location_id) {
  let path = `/videos/locations?video_id=${video_id}&location_id=${location_id}`;

  let request = await fetcher(path, "POST");

  let content = await request.json();

  return content;
}

export async function GetVideoInfo(id) {
  let path = `/videos/${id}/details`;

  let request = await fetcher(path, "GET");

  let content = await request.json();

  return content;
}

export async function UpdateVideo(
  id,
  formData,
  allLocations = false,
  allPartners = false
) {
  let path = `/videos/${id}/details?all_locations=${allLocations}&all_partners=${allPartners}`;

  let request = await fetcher(path, "PUT", formData, false, true);

  let content = await request.json();

  return content;
}
