export const getFinalCategoriesWithFirstAndLast = (
  categories,
  firstParent = ""
) => {
  const finalCategories = [];

  categories.forEach((category) => {
    const currentFirstParent = firstParent || category.category_name;

    if (category.subcategories && category.subcategories.length > 0) {
      // Recursive call for subcategories
      finalCategories.push(
        ...getFinalCategoriesWithFirstAndLast(
          category.subcategories,
          currentFirstParent
        )
      );
    } else {
      if (currentFirstParent === category.category_name) {
        // Add category with no parent
        finalCategories.push({
          ...category,
          displayName: category.category_name,
        });
        return;
      }
      // Add final category with its first parent
      finalCategories.push({
        ...category,
        displayName: `${currentFirstParent} -> ${category.category_name}`,
      });
    }
  });

  return finalCategories;
};
