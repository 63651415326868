import { getIn } from "formik";
import React from "react";
import { TextField, MenuItem } from "@mui/material";

export const TextFormField: React.FC<any> = ({ field, form, ...props }) => {
    const errorText =
        getIn(form.touched, field.name) && getIn(form.errors, field.name);
    return (
        <TextField
            {...props}
            id="outlined-basic"
            variant="outlined"
            helperText={errorText}
            error={!!errorText}
            {...field}
            InputProps={{
                sx: { borderRadius: 2 },
                style: { borderRadius: 4 },
                readOnly: props.readOnly,
            }}
            InputLabelProps={{
                style: {
                    fontFamily: 'SeagoeUI',
                    fontSize: '1em',
                },
            }}
        >
            {props.select &&
                props.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
        </TextField>
    );
};
