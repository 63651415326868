import fetcher from "../helpers/fetcher";

export async function GetCategoriesHierarchy(
  searchTerm = "",
  parent = "",
  page = 0
) {
  let path = `/categories/hierarchy?search=${searchTerm}&parent=${parent}&page=${page}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function CreateCategory(body) {
  let path = `/categories`;

  let request = await fetcher(path, "POST", body);

  let content = request.json();

  return content;
}

export async function GetAllCategories() {
  let path = `/categories/all`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

// export async function GetSpoturiDeLocatie() {
//   let path = `/categories/locations`;

//   let request = await fetcher(path, "GET");

//   let content = request.json();

//   return content;
// }
