import * as React from "react";
import { Formik, Field, Form } from "formik";
import { TextFormField } from "../../components/textform/TextFormField.tsx";
import * as yup from "yup";
import { AddUsers } from "../../services/user.js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import YupPassword from "yup-password";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import MultiSelectFormField from "../../components/multiselectform/MultiSelectFormField.tsx";
import CircularProgress from "@mui/material/CircularProgress";

YupPassword(yup);

export const form = [
  {
    name: "name",
    label: "Full Name",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },
  {
    name: "email",
    label: "Email",
    disabled: false,
    component: TextFormField,
    style: {
      width: "49%",
    },
  },

  {
    name: "password",
    label: "Password",
    disabled: false,
    component: TextFormField,
    type: "password",
    style: {
      width: "49%",
    },
  },
  {
    name: "confirm_password",
    label: "Confirm Password",
    disabled: false,
    component: TextFormField,
    type: "password",
    style: {
      width: "49%",
    },
  },
  {
    name: "role",
    label: "Role",
    disabled: false,
    multiple: true,
    component: MultiSelectFormField,
    select: true,
    options: [
      { value: "ROLE_ADMIN", label: "Admin" },
      { value: "ROLE_USER", label: "User" },
    ],
    style: {
      width: "49%",
    },
  },
];

export const validation = yup.object().shape({
  name: yup.string().required("Full Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  password: yup
    .string()
    .required("Password is required")
    .min(
      8,
      "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "password must contain at least 1 lower case letter")
    .minUppercase(1, "password must contain at least 1 upper case letter")
    .minNumbers(1, "password must contain at least 1 number")
    .minSymbols(1, "password must contain at least 1 special character"),
  confirm_password: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  role: yup.array().min(1, "At least one role is required"),
});

export default function CreateUser() {
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleError = (description) => {
    setError(true);
    setErrorDescription(description);
  };

  const submitForm = async (values) => {
    const data = {
      name: values.name,
      email: values.email,
      password: values.password,
      role: values.role,
    };

    var jsonData = JSON.stringify(data);

    try {
      setLoading(true);
      const content = await AddUsers(jsonData);

      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        navigate("/users");
      }
    } catch {
      handleError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        {error && (
          <div className="error-container">
            <span className="error-text">{errorDescription}</span>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="form-container">
          <Formik
            validationSchema={validation}
            initialValues={{
              name: "",
              email: "",
              password: "",
              confirm_password: "",
              role: [],
            }}
            onSubmit={(values) => {
              submitForm(values);
            }}
          >
            {() => (
              <Form
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="form-position">
                  {form.map((snapshot, key) => {
                    return <Field {...snapshot} key={key} />;
                  })}
                </div>
                <button className="submit-form" type="submit">
                  {loading ? <CircularProgress size={24} /> : "Create User"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
