import fetcher from "../helpers/fetcher";

export async function GetAllPartners(
  page,
  searchTerm = "",
  fetchAllInfo = true
) {
  let path = `/partners?page=${page}&search=${searchTerm}&fetchAllInfo=${fetchAllInfo}`;
  let request = await fetcher(path, "GET");
  let content = await request.json();
  return content;
}

export async function AddPartner(body) {
  let path = `/partners`;
  let request = await fetcher(path, "POST", body);
  let content = await request.json();
  return content;
}
