import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";

const focusedStyle = {
  borderColor: "rgb(51, 51, 51)",
  color: "rgb(51, 51, 51)",
};

const acceptStyle = {
  borderColor: "rgb(51, 51, 51)",
  color: "rgb(51, 51, 51)",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function StyledDropzone(
  props,
  color = "white",
  borderColor = "#CCCCFF"
) {
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 50,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: borderColor,
    borderStyle: "dashed",
    color: color,
    fontSize: "20px",
    outline: "none",
    cursor: "pointer",
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { [props.type]: [] },
      multiple: props.multiple,
      onDrop: (acceptedFiles) => {
        props.onDrop(acceptedFiles);
      },
      acceptedFiles: props.files,
      disabled: props.disabled,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} accept="video/*" />
        <p>{props.title}</p>
      </div>
    </div>
  );
}
