import fetcher from "../helpers/fetcher";

export async function GetAllLocations(
  page,
  searchTerm = "",
  fetchAll = false,
  withCoordinates = false,
  partnerId = ""
) {
  let path = `/locations?page=${page}&search=${searchTerm}&fetchAll=${fetchAll}&withCoordinates=${withCoordinates}&partnerId=${partnerId}`;
  let request = await fetcher(path, "GET");
  let content = await request.json();
  return content;
}

export async function FindParentLocations() {
  let path = `/locations/parents`;
  let request = await fetcher(path, "GET");
  let content = await request.json();
  return content;
}

export async function CreateLocation(body, partnerId) {
  let path = `/locations?partnerId=${partnerId}`;
  let request = await fetcher(path, "POST", body);
  let content = await request.json();
  return content;
}

export async function GetLocationByID(id) {
  let path = `/locations/${id}`;
  let request = await fetcher(path, "GET");
  let content = await request.json();
  return content;
}

export async function GetLocationInfo(id) {
  let path = `/locations/${id}/details`;
  let request = await fetcher(path, "GET");
  let content = await request.json();
  return content;
}

export async function SaveLocationInfo(body, id) {
  let path = `/locations/${id}`;
  let request = await fetcher(path, "PUT", body);
  let content = await request.json();
  return content;
}

export async function DeleteLocation(id) {
  let path = `/locations/${id}`;
  let request = await fetcher(path, "DELETE");
  let content = await request.json();
  return content;
}
