import fetcher from "../helpers/fetcher";

export default async function ReqUsers(page, debouncedSearchTerm = "") {
  let path = `/users?page=${page}&search=${debouncedSearchTerm}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function GetAllUsers() {
  let path = `/users`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function FindUser(id) {
  let path = `/users/${id}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function AddUsers(data) {
  let path = `/users`;

  let request = await fetcher(path, "POST", data);

  let content = request.json();

  return content;
}

export async function UpdateUser(data, id) {
  let path = `/users/${id}`;

  let request = await fetcher(path, "PUT", data);

  let content = request.json();

  return content;
}

export async function UpdateUserPass(data, id) {
  let path = `/users/password/${id}`;

  let request = await fetcher(path, "PUT", data);

  let content = request.json();

  return content;
}

export async function DeleteUsers(id) {
  let path = `/users/${id}`;

  let request = await fetcher(path, "DELETE");

  let content = request.json();

  return content;
}
