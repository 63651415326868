import React from "react";
import { TextField, FormControlLabel, Divider, Radio } from "@mui/material";
import { Film, Clock, Maximize2 } from "react-feather";

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
    "& .MuiInputLabel-root": { color: "#555" },
    "&.Mui-disabled .MuiOutlinedInput-root": { backgroundColor: "#f0f0f0" },
  },
};

export default function VideoGeneralTab({
  videoTitle,
  setVideoTitle,
  videoFile,
  videoDuration,
  isLandscape,
  setIsLandscape,
  isSubmitting,
  preview,
}) {
  return (
    <>
      <h1 className="add-video-title">Select a new video</h1>
      <Divider style={{ marginBottom: "20px" }} />
      <div
        style={{
          display: "flex",
          columnGap: "7px",
          alignItems: "center",
        }}
      >
        <Film fontSize={"1.25rem"} />
        <span style={{ fontSize: "1.25rem" }}>Video title</span>
      </div>
      <TextField
        label="Title"
        fullWidth
        variant="outlined"
        value={videoTitle}
        disabled={preview}
        onChange={(e) => setVideoTitle(e.target.value)}
        className="add-video-field"
        sx={styles.select}
      />

      <Divider />
      <div
        style={{
          display: "flex",
          columnGap: "10px",
          alignItems: "center",
        }}
      >
        <Clock fontSize={"1.25rem"} />
        <span style={{ fontSize: "1.25rem" }}>Runtime</span>
      </div>
      <TextField
        label="Duration (seconds)"
        type="number"
        disabled
        fullWidth
        variant="outlined"
        value={videoDuration}
        className="add-video-field"
        sx={styles.select}
      />
      <Divider />
      <div
        style={{
          display: "flex",
          columnGap: "10px",
          alignItems: "center",
        }}
      >
        <Maximize2 fontSize={"1.25rem"} />
        <span style={{ fontSize: "1.25rem" }}>Orientation</span>
      </div>
      <div className="orientation-options">
        {/* TODO: pentru urmatoarele module se va activa functionalitatea de orientare a videoclipului */}
        <FormControlLabel
          control={
            <Radio
              disabled={true}
              checked={isLandscape}
              onChange={() => setIsLandscape(true)}
              color="primary"
            />
          }
          label="Landscape"
        />
        <FormControlLabel
          control={
            <Radio
              disabled={true}
              checked={!isLandscape}
              onChange={() => setIsLandscape(false)}
              color="primary"
            />
          }
          label="Portrait"
          WW
        />
      </div>
    </>
  );
}
